import * as React from "react";
import {useContext, useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import to from 'await-to-js';

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Button, Grid, Switch, Typography, useTheme, Link} from "@mui/material";
import {
    CheckmarkOutline,
    Copy,
    Pause,
    PauseFilled,
    Pending,
    Play,
    Rocket,
    StopSign,
    StopSignFilled
} from "@carbon/icons-react";

import {
    AlertBanner, Chips,
    CustomButton,
    LoadingSpinner,
    Modal, Paper,
    TableContainer
} from "@medtronic/pecc-react-component-library-js";

import {process_data_for_table} from "../../utils/table_utils";
import ConfirmationDialog from "./ConfirmationDialog";
import {APIContext} from "../../utils/api-context";
import moment from "moment/moment";
import WorkbenchView from "../pages/workbench-view";
import ControlledConfirmModal from "./controlled-confirm-modal";

const PENDINGSTATUS = "pending";
const PENDINGSTARTSTATUS = "pending-start";
const PENDINGSTOPSTATUS = "pending-stop";
const STOPPINGSTATUS = "stopping";
const STOPPEDSTATUS = "stopped";
const INITIALIZINGSTATUS = "initializing";
const RUNNINGSTATUS = "running";
const TERMINATINGSTATUS = "terminating";
const TERMINATEDSTATUS = "terminated";
const TERMINATEDDELETEDSTATUS = "terminated-deleted";
const NOTFOUNDSTATUS = "unknown/not-found";
const USERTERMINATEDSTATUSES = [TERMINATEDSTATUS, TERMINATEDDELETEDSTATUS, NOTFOUNDSTATUS];
const REFRESHSTATUS = "please refresh...";

const confirmationDialogTimeout_s = 10;
const confirmationDialogTimeout_ms = confirmationDialogTimeout_s * 1000;

async function updateWorkbenchClusterStatuses(workbench, apiManager) {
  const allClusters = apiManager.jobClusterManager.dataArray;
  if (workbench) {
    let jobCluster = apiManager.getWorkbenchJobCluster(workbench.id);
    if (jobCluster) {
      const [clusterStatusError, clusterStatusResult] = await to(
        apiManager.getJobClusterInstanceStatuses(jobCluster));
      for (let cluster of allClusters) {
        if (cluster.workbench_id === workbench.id){
          cluster.status = clusterStatusResult
        }
      }
    }
  }
}

function WorkbenchControlPanel({workbenchEventMediator, jobClusterEventMediator}) {
    let {projectid} = useParams();
    const [selectedWorkbench, setSelectedWorkbench] = useState(null);
    const [refreshKey, setRefreshKey] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [alertBannerMessage, setAlertBannerMessage] = useState();
    const [createWorkbenchModalOpen, setCreateWorkbenchModalOpen] = useState(false);
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    const project = apiManager.projectManager.dataCache[projectid];
    const projectPrivileges = apiManager.projectRoleManager.getBlankProjectPrivileges();
    apiManager.projectRoleManager.getRolePrivilege(user.projectRoles[projectid].role_id, projectPrivileges);
    let [allWorkbenches, setAllWorkbenches] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [refreshOnCooldown, setRefreshOnCooldown] = useState(false);
    allWorkbenches = apiManager.workbenchManager.dataArray;
    let projectWorkbenches = allWorkbenches.filter(wb => wb.project_id === project.id);

    const [showTerminated, setShowTerminated] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(true);
    
    function refreshWorkbenchTable() {
         setUpdating(true);
         setRefreshOnCooldown(true);
         updateWorkbenches(refreshKey, setRefreshKey);
         setTimeout(() => {
             setRefreshOnCooldown(false);
         }, apiManager.workbenchManager.fetchEveryMilliseconds);
     }
 
  
     useEffect(() => {
        if (refreshTrigger) {
          refreshWorkbenchTable();
          setRefreshTrigger(false); // Reset trigger
        }
      }, [refreshTrigger,refreshWorkbenchTable]);

         
    const updateWorkbenches = async (refreshKey, setRefreshKey) => {
        allWorkbenches = apiManager.workbenchManager.dataArray;
        console.log(`User has the ability to view workbench stop button: ${apiManager.getCurrentUser().privileges.workbench.canStop}`);
        console.log(`User has the ability to view workbench terminate button: ${apiManager.getCurrentUser().privileges.workbench.canTerminate}`);
        console.log(`User has the ability to view workbench start button: ${apiManager.getCurrentUser().privileges.workbench.canStart}`);
        if (allWorkbenches) {
            project.workbenches = projectWorkbenches;
        } else {
            project.workbenches = [];
        }
        for (let workbench of allWorkbenches) {
            if (!workbench.metadata || !workbench.metadata.InstanceId) {
                console.log(`Workbench '${workbench.name}' with id ${workbench.id} is missing instance id`);
                let [udpatedWBError, updatedWorkbench] = await to (apiManager.workbenchManager.getWorkbench(workbench.id));
                if (updatedWorkbench) {
                    console.log(updatedWorkbench);
                }
            }
        }
        if (selectedWorkbench) {
          console.log("Selected workbench found, updating cluster status...")
          const [statusError, statusResult] = await to(updateWorkbenchClusterStatuses(selectedWorkbench, apiManager));
        }

        apiManager.getUpdatedWorkbenchStatuses(project, updateWorkbenchTable);
    }

    const updateWorkbenchTable = (statuses) => {
        console.log("Statuses retrieved:");
        console.log(statuses);
        console.log("Refreshing workbench table ...");
        setRefreshKey(true);
        setTimeout(() => {
            setRefreshKey(false);
            setUpdating(false);
        }, 100);
    }

   

    return <Box sx={{padding: "1rem"}}>
        <Stack spacing={3}>
            <Stack direction="row" useFlexGap justifyContent="space-between">
                <Typography variant="h3">Workbench Management</Typography>
                <Stack direction="row-reverse" spacing={2} alignItems="center">
                    <CustomButton disabled={!projectPrivileges.workbenchTab.canCreate || project.status === "inactive"}
                                  buttonType="primary"
                                  label="Create Workbench"
                                  size="large"
                                  onClick={() => setCreateWorkbenchModalOpen(true)}
                    />
                    <CustomButton buttonType="outlined"
                                  size="large"
                                  isLoading={updating}
                                  disabled={updating || refreshOnCooldown || (projectWorkbenches && projectWorkbenches.length === 0) || !Object.keys(user.projectRoles).includes(projectid)}
                                  title={refreshOnCooldown ? "Button disabled due to refresh rate limits" : ""}
                                  onClick={refreshWorkbenchTable}
                    >
                        {updating ? "Updating" : "Refresh"}
                        {updating &&
                            <Box sx={{paddingLeft: "1rem"}}><LoadingSpinner spinnerSize="xs"/></Box>
                        }
                    </CustomButton>
                    <Stack direction="row" alignItems="center">
                        <Switch onChange={() => setShowTerminated(!showTerminated)}/>
                        <Typography variant="label">Show terminated workbenches</Typography>
                    </Stack>
                </Stack>
            </Stack>
            {alertBannerMessage && <AlertBanner
                alertMessage={alertBannerMessage}
                alertType="important"
                bannerType="snackbar"
                snackbarAutoHideDuration={confirmationDialogTimeout_ms}
            />}
            {refreshKey ?
                <Stack direction="row" spacing={2}>
                    <LoadingSpinner spinnerSize="l"/>
                    <Typography variant="h2">Loading...</Typography>
                </Stack> :
                <WorkbenchTable
                    selectedWorkbench={selectedWorkbench}
                    setSelectedWorkbench={setSelectedWorkbench}
                    showTerminated={showTerminated}
                    setAlertBannerMessage={setAlertBannerMessage}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                />
            }
            {
                (!!selectedWorkbench && user.privileges.jobCluster.canView) &&
                <ClusterTable
                    selectedWorkbench={selectedWorkbench}
                    projectid={projectid}
                />
            }
            <Modal
                modalTitle="Create New Workbench"
                open={createWorkbenchModalOpen}
                toggleOpenFn={() => {setCreateWorkbenchModalOpen(!createWorkbenchModalOpen)}}
            >
                <WorkbenchView
                    setAlertMessage={setAlertBannerMessage}
                    refreshFn={refreshWorkbenchTable}
                    closeFn={() => {
                        setCreateWorkbenchModalOpen(false);
                    }}
                    eventMediator={workbenchEventMediator}
                    refresh_btn={() => setRefreshTrigger(true)} // Pass function as a callback
                />
            </Modal>
        </Stack>
    </Box>
}

function WorkbenchTableHeader({columnConfig}) {
    return columnConfig.map(col => (
        <Grid item xs={col.columnWidth}>
            <Typography fontWeight={600} lineHeight="1.6rem">
                {col.label}
            </Typography>
        </Grid>
    ));
}

function WorkbenchRows({columnConfig, workbenches, selectedWorkbench, setSelectedWorkbench, setAlertBannerMessage, copyConnectCommandsWorking, setCopyConnectCommandsWorking, refreshKey, setRefreshKey}) {
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    const {palette, typography} = useTheme();
    const [connectionDialogOpen, setConnectionDialogOpen] = useState("");
    let connectionInstructions = "";

    function WorkbenchRow({workbench, columnsConfig, selectedWorkbench, setSelectedWorkbench, setAlertBannerMessage, copyConnectCommandsWorking, setCopyConnectCommandsWorking, refreshKey, setRefreshKey}) {
        const attachedClusters = apiManager.jobClusterManager.dataArray.filter(cluster => cluster.workbench_id === workbench.id);

        function WorkbenchCell({workbench, cellConfig, attachedClusters, selectedWorkbench, setSelectedWorkbench, copyConnectCommandsWorking,  setCopyConnectCommandsWorking, setAlertBannerMessage, tableRefreshKey, setTableRefreshKey}) {
            const [cellRefreshKey, setCellRefreshKey] = useState(false);

            // Get the value of the attribute in the config in dot notation no matter how deep in the object it is (as long as it is named and not a specific place in an array)
            let content = workbench;
            let key_attr = cellConfig.key.split('.');
            for (let attr of key_attr) {
                content = content[attr];
            }

            const [terminateConfirmationDialogOpen, setTerminateConfirmationDialogOpen] = useState(false);
            const [stopConfirmationDialogOpen, setStopConfirmationDialogOpen] = useState(false);


            const selectWorkbench = async (a) => {
                const [statusError, statusResult] = await to(updateWorkbenchClusterStatuses(workbench, apiManager));
                setSelectedWorkbench(workbench);
            }

            const handleTerminateWorkbench = () => {
                setTerminateConfirmationDialogOpen(true);
            }

            const terminateCluster = (cluster) => {
                console.log(`Cluster ${cluster.name} terminating...`);
                apiManager.jobClusterManager.deleteJobCluster(cluster.id);
            }

            const terminateWorkbench = () => {
                if (attachedClusters.length > 0) {
                    terminateCluster(attachedClusters[0]);
                }

                console.log(`Workbench ${workbench.name} (${workbench.id}) terminating...`);
                apiManager.workbenchOpManager.terminateWorkbench(workbench); // Do something with result?
                workbench.status = "terminating";
                setStopConfirmationDialogOpen(false);
            }

            const handleStopWorkbench = () => {
                setStopConfirmationDialogOpen(true);
            }

            const stopWorkbench = () => {
                console.log(`Workbench ${workbench.name} (${workbench.id}) stopping...`);
                apiManager.workbenchOpManager.stopWorkbench(workbench); // Do something with result?
                workbench.status = PENDINGSTOPSTATUS;
                setStopConfirmationDialogOpen(false);
            }
            const startWorkbench = () => {
                console.log(`Workbench ${workbench.name} (${workbench.id}) starting...`);
                apiManager.workbenchOpManager.startWorkbench(workbench); // Do something with result?
                workbench.status = PENDINGSTARTSTATUS;
                setRefreshKey(!refreshKey);
            }

            const onIdClick = async () => {
                setCopyConnectCommandsWorking(workbench.id);
                const [credsError, creds] = await to(apiManager.workbenchManager.connectToWorkbench(workbench.id));
                console.log(creds);

                let workbenchPassword = workbench.metadata.Password;
                let workbenchInstanceId = workbench.metadata.InstanceId;
                let workbenchConfigId = workbench.metadata.ConfigId;
                let workbenchConfig = null;
                for (let configTemplate of apiManager.configurationsManager.dataCache["workbench"]) {
                    if (configTemplate.id === workbenchConfigId) {
                        workbenchConfig = configTemplate;
                        break;
                    }
                }
                if (!workbenchConfig) {
                    console.error(`Workbench config not found: ${workbenchConfigId}, unable to display conection info`);
                    return;
                }
                let connectionInstructionArray = workbenchConfig.connectionInstructions;
                connectionInstructions = connectionInstructionArray.join("---");
                connectionInstructions = connectionInstructions.replaceAll("{{password}}",workbenchPassword);
                connectionInstructions = connectionInstructions.replaceAll("{{AccessKeyId}}",creds.AccessKeyId);
                connectionInstructions = connectionInstructions.replaceAll("{{SecretAccessKey}}",creds.SecretAccessKey);
                connectionInstructions = connectionInstructions.replaceAll("{{SessionToken}}",creds.SessionToken);
                connectionInstructions = connectionInstructions.replaceAll("{{instanceId}}",workbenchInstanceId);
                console.log("**********************************************************************")
                console.log(connectionInstructions);
                setConnectionDialogOpen(connectionInstructions ? connectionInstructions : "There was an error fetching connection instructions.");
                setCopyConnectCommandsWorking(false);

            }

            const text_style = "body2";

            if (cellConfig.key === "instance_type") {
                if (workbench.metadata.ConfigName) {
                    content = workbench.metadata.ConfigName;
                } else {
                    content = "Custom";
                }

                content = <Typography variant={text_style}>
                    {content}
                </Typography>
            } else if (cellConfig.key === "name") {
                const buttonLabel = (
                    <Typography variant={text_style}>
                        {content.length > cellConfig.maxCharacters ? content.slice(0, cellConfig.maxCharacters-3) + "..." : content}
                    </Typography>
                );
                content = <Stack direction="row" alignItems="center" spacing={1}>
                <Link sx={{color:palette.electricBlue.main, cursor:"pointer", textDecoration:"none"}} onClick={() => { setSelectedWorkbench(workbench);}}>
                {(selectedWorkbench && selectedWorkbench.id === workbench.id) && 
                
                    (
                    <span
                    style={{
                        marginRight: "8px", 
                        verticalAlign: "text-top",  
                        }}
                    >
                        <CheckmarkOutline fill={palette.electricBlue.main} />
                    </span>
                    )
              
                }
                 {content}
                </Link>
                </Stack>
            } else if (cellConfig.id === "created_date") {
                content = <Typography variant={text_style}>
                    {moment.utc(workbench[cellConfig.key]).local().format('MM/DD/YYYY')}
                </Typography>
            } else if (typeof content === 'object') {
                content = <Typography variant={text_style}>
                    {JSON.stringify(workbench[cellConfig.key], null, 4)}
                </Typography>
            } else if (cellConfig.id === "created_by") {

                let workbench_creator = null;
                if (content === "system") {
                    workbench_creator = {first_name: "Testing", last_name: "System"};
                }
                else {
                    workbench_creator = apiManager.appUserManager.dataCache[content];
                }

                content = <Typography variant={text_style}>
                    {`${workbench_creator.first_name} ${workbench_creator.last_name}`}
                </Typography>
            } else if (cellConfig.id === "status") {
                let wkb_status = workbench[cellConfig.key];
                if (wkb_status === "unknown/not-found") {
                    wkb_status = "terminated";
                }
                let chipColor;
                let chipIcon;
                switch (wkb_status) {
                    case STOPPEDSTATUS:
                        chipColor = 'criticalHighlight.main';
                        chipIcon = <Pause />;
                        break;
                    case INITIALIZINGSTATUS:
                        chipColor = 'cautionHighlight.main';
                        chipIcon = <Rocket />;
                        break;
                    case PENDINGSTATUS:
                    case PENDINGSTARTSTATUS:
                    case PENDINGSTOPSTATUS:
                    case STOPPINGSTATUS:
                    case TERMINATINGSTATUS:
                    case REFRESHSTATUS:
                        chipColor = 'cautionHighlight.main';
                        chipIcon = <Pending />;
                        break;
                    case RUNNINGSTATUS:
                        chipColor = 'success.light';
                        chipIcon = <Play />;
                        break;
                    default:
                        chipColor = 'black10.main';
                        chipIcon = <StopSign />;
                        break;
                }
                content = <Box marginLeft="-13px"><Chips chipsConfig={[{
                    label: <Typography variant={text_style}>
                            {wkb_status}
                        </Typography>,
                    type: "customSolid",
                    customColor: chipColor,
                    customIcon: chipIcon
                }]} alignment="left"/></Box>
            } else if (cellConfig.id === 'controls') {
                content = <WorkbenchTableRowControls
                    user={user}
                    workbench={workbench}
                    attachedClusters={attachedClusters}
                    selectedWorkbench={selectedWorkbench}
                    setSelectedWorkbench={selectWorkbench}
                    stopConfirmationDialogOpen={stopConfirmationDialogOpen}
                    setStopConfirmationDialogOpen={setStopConfirmationDialogOpen}
                    terminateConfirmationDialogOpen={terminateConfirmationDialogOpen}
                    setTerminateConfirmationDialogOpen={setTerminateConfirmationDialogOpen}
                    refreshKey={cellRefreshKey}
                    setRefreshKey={setCellRefreshKey}
                />
            } else if (cellConfig.id === 'instance_id') {
                content = <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant={text_style}>
                        {content}
                    </Typography>
                    <button onClick={onIdClick}
                            title="Copy to clipboard the terminal commands for connecting to the workbench"
                            variant="outlined"
                            disabled={!(workbench.status === RUNNINGSTATUS) || copyConnectCommandsWorking}
                            style={{
                                padding: "2px 3px",
                                border: "1px solid grey",
                                borderRadius: "6px",
                                cursor: workbench.status === RUNNINGSTATUS ? "pointer" : "not-allowed"
                            }}
                    >
                        {copyConnectCommandsWorking === workbench.id ? <LoadingSpinner spinnerSize="xs"/> : <Copy/>}
                    </button>
                </Stack>
            } else {
                content = <Typography variant={text_style}>
                    {content}
                </Typography>
            }

            return <Grid item xs={cellConfig.columnWidth}>
                {content}
            </Grid>
        }

        return columnsConfig.map(columnConfig => (
            <WorkbenchCell workbench={workbench}
                           cellConfig={columnConfig}
                           attachedClusters={attachedClusters}
                           selectedWorkbench={selectedWorkbench}
                           setSelectedWorkbench={setSelectedWorkbench}
                           setAlertBannerMessage={setAlertBannerMessage}
                           copyConnectCommandsWorking={copyConnectCommandsWorking}
                           setCopyConnectCommandsWorking={setCopyConnectCommandsWorking}
                           tableRefreshKey={refreshKey}
                           setTableRefreshKey={setRefreshKey}
            />
        ));
    }

    return <>
        {workbenches.map(workbench => (
            <WorkbenchRow workbench={workbench}
                          columnsConfig={columnConfig}
                          selectedWorkbench={selectedWorkbench}
                          setSelectedWorkbench={setSelectedWorkbench}
                          setAlertBannerMessage={setAlertBannerMessage}
                          copyConnectCommandsWorking={copyConnectCommandsWorking}
                          setCopyConnectCommandsWorking={setCopyConnectCommandsWorking}
                          refreshKey={refreshKey}
                          setRefreshKey={setRefreshKey}
            />
        ))}
        <ConfirmationDialog
            open={connectionDialogOpen}
            handleClose={() => setConnectionDialogOpen("")}
            dialogTitle={`Connection Instructions`}
            dialogContent={<><p>
              {connectionDialogOpen.split && connectionDialogOpen.replaceAll && connectionDialogOpen.replaceAll("&nbsp","\u00A0").split("---").map((line) => (

                  <div>{line}</div>

              ))}

            </p></>}
            primaryLabel="Ok"
            onPrimaryClick={() => {
                setConnectionDialogOpen(false);
            }}
            secondaryLabel="Cancel"
            onSecondaryClick={() => setConnectionDialogOpen("")}
        />
    </>
}

function WorkbenchTableRowControls({workbench, attachedClusters, selectedWorkbench, setSelectedWorkbench, stopConfirmationDialogOpen, setStopConfirmationDialogOpen, terminateConfirmationDialogOpen, setTerminateConfirmationDialogOpen, refreshKey, setRefreshKey}) {
    const apiManager = useContext(APIContext);
    const attachedClusterInfo = attachedClusters.length > 0 ? attachedClusters[0] : null;

    // Here are all the functions needed for interacting with cells and rows
    const terminateCluster = (cluster) => {
        console.log(`Cluster ${cluster.name} terminating...`);
        apiManager.jobClusterManager.deleteJobCluster(cluster.id);
    }

    const terminateWorkbench = () => {
        if (attachedClusterInfo) {
            terminateCluster(attachedClusters[0])
        }

        console.log(`Workbench ${workbench.name} (${workbench.id}) terminating...`);
        apiManager.workbenchOpManager.terminateWorkbench(workbench).then(() => {
            setRefreshKey(!refreshKey);
        });
        workbench.status = "terminating";
        setTerminateConfirmationDialogOpen(false);
    }

    const handleStopWorkbench = () => {
        setStopConfirmationDialogOpen(true);
    }

    const stopWorkbench = () => {
        console.log(`Workbench ${workbench.name} (${workbench.id}) stopping...`);
        apiManager.workbenchOpManager.stopWorkbench(workbench).then(() => {
            setRefreshKey(!refreshKey);
        }); // Do something with result?
        workbench.status = PENDINGSTOPSTATUS;
        setStopConfirmationDialogOpen(false);
    }
    const startWorkbench = () => {
        console.log(`Workbench ${workbench.name} (${workbench.id}) starting...`);
        apiManager.workbenchOpManager.startWorkbench(workbench); // Do something with result?
        workbench.status = PENDINGSTARTSTATUS;
        setRefreshKey(!refreshKey);
    }

    return <>
        <Stack direction="row" spacing={0.75}  useFlexGap flexWrap="wrap">
            {apiManager.getCurrentUser().privileges.workbench.canStart &&
                <CustomButton
                    variant="outlined"
                    color="green"
                    onClick={startWorkbench}
                    disabled={![STOPPEDSTATUS].includes(workbench.status.toLowerCase())}
                    sx={{borderRadius: "1.5em"}}>
                    <Typography variant="body2">
                        {workbench.status === PENDINGSTARTSTATUS ? "Starting..." : "Start"}
                    </Typography>
                </CustomButton>}
            {apiManager.getCurrentUser().privileges.workbench.canStop &&
                <CustomButton
                    variant="outlined"
                    color="red"
                    onClick={() => setStopConfirmationDialogOpen(true)}
                    disabled={![INITIALIZINGSTATUS, RUNNINGSTATUS].includes(workbench.status.toLowerCase())}
                    sx={{borderRadius: "1.5em"}}>
                    <Typography variant="body2">
                        {workbench.status === PENDINGSTOPSTATUS ? "Stopping..." : "Stop"}
                    </Typography>
                </CustomButton>}
            {apiManager.getCurrentUser().privileges.workbench.canTerminate &&
                <CustomButton
                    variant="outlined"
                    color="black90"
                    disabled={![STOPPEDSTATUS, INITIALIZINGSTATUS, RUNNINGSTATUS].includes(workbench.status.toLowerCase())}
                    onClick={() => setTerminateConfirmationDialogOpen(true)}
                    sx={{borderRadius: "1.5em"}}
                >
                    <Typography variant="body2">Terminate</Typography>
                </CustomButton>}
        </Stack>
        <ControlledConfirmModal
            open={terminateConfirmationDialogOpen}
            toggleOpenFn={() => setTerminateConfirmationDialogOpen(!terminateConfirmationDialogOpen)}
            modalTitle={attachedClusterInfo ? "Terminate this workbench and cluster?" : "Terminate this workbench?"}
            modalDescription={<>
                <Typography variant="p" sx={{display: "block"}}>
                    {attachedClusterInfo ?
                        `Terminating the workbench "${workbench.name}" will permanently remove it and its associated job-cluster "${attachedClusterInfo.name}". Any data stored within this workbench or the corresponding S3 bucket will be lost unless saved to a Project S3 bucket.`
                        :
                        `Terminating the workbench "${workbench.name}" will permanently remove it. Any data stored within this workbench or the corresponding S3 bucket will be lost unless saved to a Project S3 bucket.`
                    }
                </Typography>
                <Typography variant="p" sx={{marginTop: "1rem", display: "block"}}>
                    {attachedClusterInfo ?
                        `Do you want to continue and terminate this workbench and its job cluster?`
                    :
                        `Do you want to continue and terminate this workbench?`}
                </Typography>
            </>}
            // controlType="text"
            // controlString={attachedClusterInfo ? "CLUSTER" : "TERMINATE"}
            primaryButtonLabel="Terminate"
            primaryButtonHandler={terminateWorkbench}
            secondaryButtonLabel="Cancel"
            secondaryButtonHandler={() => setTerminateConfirmationDialogOpen(false)}
        />
        <ConfirmationDialog
            open={stopConfirmationDialogOpen}
            handleClose={() => setStopConfirmationDialogOpen(false)}
            dialogTitle={`Stop the workbench ${workbench.name}?`}
            dialogContent={<>
                <Typography variant="p" sx={{display: "block"}}>
                    Stopping the workbench will shut down the EC2 instance associated with it. Data in persistent
                    storage will be preserved and accessible once the workbench is restarted.
                </Typography>
                {!!attachedClusterInfo && <Typography variant="p" sx={{display: "block", marginTop: "0.75rem"}}>
                    {`The "${attachedClusterInfo.name}" job cluster associated with this workbench will keep running.`}
                </Typography>}
            </>}
            primaryLabel="Stop"
            onPrimaryClick={() => {
                stopWorkbench();
                setStopConfirmationDialogOpen(false);
            }}
            secondaryLabel="Cancel"
            onSecondaryClick={() => setStopConfirmationDialogOpen(false)}
        />
    </>;
}

function WorkbenchTable({selectedWorkbench, setSelectedWorkbench, setAlertBannerMessage, refreshKey, setRefreshKey, showTerminated}) {
    const {projectid} = useParams();
    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    const projectPrivileges = apiManager.projectRoleManager.getBlankProjectPrivileges();
    apiManager.projectRoleManager.getRolePrivilege(user.projectRoles[projectid].role_id, projectPrivileges);
    let projectWorkbenches = apiManager.workbenchManager.dataArray.filter(wb => wb.project_id == projectid);
    const controlsVisible = projectPrivileges.workbenchTab.canOperate;
    const [copyConnectCommandsWorking, setCopyConnectCommandsWorking] = useState(false);

    if (!showTerminated) {
        projectWorkbenches = projectWorkbenches.filter(wkb => !USERTERMINATEDSTATUSES.includes(wkb.status))
    }

    const workbench_cols = [
        {id: "workbench_name",
            label: "Name",
            key: "name",
            columnWidth: 3,
            maxCharacters: 50
        }, {id: "status",
            label: "Instance Status",
            key: "status",
            columnWidth: 1.3
        }, {id: "type",
            label: "Type",
            key: "instance_type",
            columnWidth: 1.5
        }, {id: "created_by",
            label: "Created By",
            key: "created_by",
            columnWidth: 1.2
        }, {id: "created_date",
            label: "Created On",
            key: "created",
            columnWidth: 1
        }, {id: "controls",
            label: "Controls",
            key: "controls",
            columnWidth: 2.25
        }, {id: "instance_id",
            label: "Instance Id",
            key: "metadata.InstanceId",
            columnWidth: 1.75
        }
    ]

    return projectWorkbenches.length > 0 ?
        <Paper sx={{margin: "1rem", padding: "2rem"}}>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <WorkbenchTableHeader
                    columnConfig={workbench_cols}
                />
                <WorkbenchRows
                    columnConfig={workbench_cols}
                    workbenches={projectWorkbenches}
                    selectedWorkbench={selectedWorkbench}
                    setSelectedWorkbench={setSelectedWorkbench}
                    setAlertBannerMessage={setAlertBannerMessage}
                    copyConnectCommandsWorking={copyConnectCommandsWorking}
                    setCopyConnectCommandsWorking={setCopyConnectCommandsWorking}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                />
            </Grid>
        </Paper>
        : <Typography variant="h4">
            {`There aren't any ${!showTerminated ? 'active ' : ''}workbenches attached to this project.`}
        </Typography>
}

function ClusterTable({selectedWorkbench, projectid}) {

    const [addClusterDisabled, setAddClusterDisabled] = useState(true);
    const apiManager = useContext(APIContext);

    // Check if the parent workbench associated for the cluster is terminated
    function updateAddClusterButton(statuses) {
        let canAddCluster = true;
        for (let k in statuses) {
            if ((statuses[k] === NOTFOUNDSTATUS) || ((statuses[k] === TERMINATEDSTATUS))) {
                canAddCluster = false;
            }
        }
        if (canAddCluster) {
            setAddClusterDisabled(false);
        } else {
            setAddClusterDisabled(true);
        }
    }

    // Fetch the current status of the workbench
    function canAddCluster(parentWorkbench) {
        let instanceIds = [];
        instanceIds.push(parentWorkbench.metadata.InstanceId);
        apiManager.workbenchManager.getWorkbenchStatuses(instanceIds, updateAddClusterButton);
    }

    canAddCluster(selectedWorkbench);

    const cluster_cols = [{
        id: "name", label: "Name", key: "name"
    }, {
        id: "cluster_type", label: "Cluster Type", key: "cluster_type"
    }, {
        id: "cluster_status", label: "Cluster Status", key: "status"
    }, {
        id: "current_job_status", label: "Job Status", key: "current_job_status"
    }, {
        id: "created_by", label: "Created By", key: "created_by"
    }]
    let [allClusters, setAllClusters] = useState([]);

    let currentClusterStatus = "refresh needed...";
    allClusters = apiManager.jobClusterManager.dataArray;

    const filter_rows = allClusters.filter(row => {
        return row.workbench_id == selectedWorkbench.id;
    });
    const cluster_rows = process_data_for_table(filter_rows, cluster_cols);
    if (cluster_rows && cluster_rows[0]) {
      cluster_rows[0]['status'] = currentClusterStatus;
    }

    return (cluster_rows.length > 0 ?
            <Box sx={{width: "100%"}}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">
                        {`Clusters for ${selectedWorkbench.name}`}
                    </Typography>
                </Stack>
                <TableContainer
                    columns={cluster_cols}
                    rows={cluster_rows}
                    heading="Table"
                    isLoading={false}
                    hasError={false}
                    elevation={2}
                    zebra={true}
                    rowsPerPageOptions={[10, 25, 50, 100]} 
                    defaultRowsPerPage={10} 
                    hidePagination={false} 
                    totalRowCount={cluster_rows.length} 
                />
            </Box> :
            <Box sx={{width: "100%"}}>
                <Typography variant="h4">
                    {`No clusters for ${selectedWorkbench.name}`}
                </Typography>
                <CustomButton buttonType="outlined" label="Add Cluster"
                        disabled={addClusterDisabled}
                        href={`/projects/${projectid}/workbench/${selectedWorkbench.id}/newcluster`}>Add
                    Cluster</CustomButton>
            </Box>
    )
}

export default WorkbenchControlPanel;
