const projectsFields = [
	{
		"id": "name",
		"label": "Name",
		"key": "name",
	},
	{
		"id": "application_id",
		"label": "App ID",
		"key": "application_id",
	},
	{
		"id": "operating_unit_id",
		"label": "OU ID",
		"key": "operating_unit_id",
	},
	{
		"id": "description",
		"label": "Description",
		"key": "description",
	},
	{
		"id": "type",
		"label": "Type",
		"key": "type",
	},
	{
		"id": "status",
		"label": "Status",
		"key": "status",
	},
	{
		"id": "group_name",
		"label": "Group Name",
		"key": "group_name",
	},
	{
		"id": "cost_center",
		"label": "Cost Center",
		"key": "cost_center",
	},
	{
		"id": "created_by",
		"label": "Created By",
		"key": "created_by",
	},
	{
		"id": "created",
		"label": "Created",
		"key": "created",
	},
	{
		"id": "updated_by",
		"label": "Updated By",
		"key": "updated_by",
	},
	{
		"id": "updated",
		"label": "Updated",
		"key": "updated",
	},
	{
		"id": "controls",
		"label": "",
		"key": "id",
	},
]

export default projectsFields;
