import { useContext, useState } from "react";
import { APIContext } from "../../utils/api-context";
import Stack from "@mui/material/Stack";
import {
    AlertBanner,
    CustomButton,
    Modal,
    Paper,
    SearchInput,
    TableContainer
} from "@medtronic/pecc-react-component-library-js";
import datasetsFields from "../../entity_attributes/datasets_fields";
import { process_data_for_table } from "../../utils/table_utils";
import { Box, Snackbar, Alert, Typography, Tooltip } from "@mui/material";
import DatasetViewModal from "./dataset-view-modal";
import DatasetAccessRequestForm from "../dataset-access-request-form";
import moment from "moment/moment";

import EventMediator from "../../utils/event-mediator";
import RestNotifier from "./rest-notifier"

function DatasetsDisplayTable() {
    const formValuesDefault = {
        hdpar_cost_center: null,
        use_cases: [],
        use_case_description: null,
        business_value: null,
    }
    const eventMediator = new EventMediator();
    let columns = datasetsFields;
    let data = [];

    const apiManager = useContext(APIContext);
    const user = apiManager.getCurrentUser();
    const numDatasets = Object.keys(apiManager.datasetManager.dataCache).length;

    const [viewRecordOpen, setViewRecordOpen] = useState(false);
    const [snowModalOpen, setSnowModalOpen] = useState(false);
    const [openDataset, setOpenDataset] = useState(-1);
    const [pendingDataAccessRequests, setPendingDataAccessRequests] = useState([]);
    const [datasetAccessRequestFormValues, setDatasetAccessRequestFormValues] = useState(formValuesDefault);
    const [errorAlertMessage, setErrorAlertMessage] = useState();
    const [successMsg, setSuccessMsg] = useState();
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
    let [searchText, setSearchText] = useState("");


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success', 'error'


    function DataCatalogRowControls({ datasetId, pendingIds }) {
        const dataset = apiManager.datasetManager.dataCache[datasetId];
        const intDatasetId = parseInt(datasetId);

        let requestButtonLabel;
        let requestButtonDisabled;
        let requestButtonVisible;

        if (pendingIds.includes(intDatasetId)) {
            requestButtonLabel = "Request Submitted";
            requestButtonDisabled = true;
            requestButtonVisible = true;
        } else if (!!user.datasetRoles[datasetId]) {
            if (["pending_reader", "pending_writer"].includes(user.datasetRoles[datasetId].role_name)) {
                requestButtonLabel = "Access Pending";
            } else {
                requestButtonLabel = "Access Granted"
            }
            requestButtonDisabled = true;
            requestButtonVisible = true;
        } else {
            requestButtonLabel = "Request Access";
            requestButtonDisabled = false;
            requestButtonVisible = datasetId && apiManager.datasetManager.dataCache[datasetId].status.toLowerCase() === "active";
        }

        return <Stack>
            <CustomButton buttonType="borderless" label="View" onClick={() => {
                console.log(`View dataset ${datasetId} clicked!`);
                setOpenDataset(datasetId);
                setDatasetAccessRequestFormValues(formValuesDefault);
                setViewRecordOpen(true);
            }} />
            {requestButtonVisible &&
                <CustomButton buttonType="borderless"
                    label={requestButtonLabel}
                    disabled={requestButtonDisabled}
                    onClick={() => {
                        console.log(`Request access for dataset ${datasetId} clicked!`);
                        setOpenDataset(datasetId);
                        setDatasetAccessRequestFormValues(formValuesDefault);
                        setSnowModalOpen(true);
                    }}
                />
            }
        </Stack>;
    }

    if (apiManager.datasetManager && numDatasets > 0) {
        data = processDatasetRows(apiManager.datasetManager.dataArray, searchText, "name", true);
        data = process_data_for_table(data, columns);
        data.map(dataset_row => {
            console.log(dataset_row);

            for (let value of dataset_row.values) {
                if (value.id.endsWith("id") && value.value) {
                    let dataset_id = value.value;
                    value.value = <DataCatalogRowControls datasetId={dataset_id} pendingIds={pendingDataAccessRequests} />
                }
                if (value.id.endsWith("source") && value.value) {
                    let source = value.value;
                    value.value = (
                        <Typography
                            variant="body2"
                            sx={{
                                maxWidth: '10em',
                                wordWrap: 'break-word',
                                whiteSpace: 'normal'
                            }}
                        >
                            {source}
                        </Typography>
                    );
                }
            }

            return dataset_row
        })
        console.log(data);
    } else {
        data = [];
    }

    function handleDatasetSearchChange(event) {
        setTimeout(setSearchText(event.input), 1000);
    }

    function handleDataAccessRequestSubmit() {
        console.log("Submit button clicked~!");

        const [areInputValuesValid, inputDisqualifications] = verifyDatasetAccessRequestForm(datasetAccessRequestFormValues);
        const dataset = apiManager.datasetManager.dataCache[openDataset];
        const request_ref_id = `${user.id}-${dataset.id}-${moment().format("YYMMDDHHmmssSS")}`;

        if (areInputValuesValid) {
            setErrorAlertMessage("");

            console.log("Inputs verified. Submitting...");

            const snowPayload = {
                user_id: user.profile.NTID ? user.profile.NTID : null,
                app_user_id: user.id,
                dataset_name: dataset.name,
                cost_center: datasetAccessRequestFormValues.hdpar_cost_center,
                org_unit: apiManager.operatingUnitManager.dataCache[user.operating_unit_id].name,
                dataset_primary_key: dataset.id.toString(),
                controlling_ad_group: dataset.metadata.active_directory_name,
                data_owners: [dataset.metadata.data_owner_network_id, dataset.metadata.secondary_data_owner_network_ids].flat(),
                data_classification: dataset.data_classification,
                primary_secondary_use_case: datasetAccessRequestFormValues.use_cases.map(reason => reason.optionLabel),
                use_case_description: datasetAccessRequestFormValues.use_case_description,
                business_value: datasetAccessRequestFormValues.business_value,
            }

            console.log(snowPayload);

            apiManager.serviceNowManager.createNewTicket(snowPayload)
                .then(resp => {
                    if (resp && resp.status === 200) {
                        // Handle success
                        setSnackbarMessage("Dataset access request submitted successfully");
                        setSnackbarSeverity("success"); // Green
                    } else {
                        // Handle error response
                        const errorMessage = `Error ${resp.status}: ${resp.statusText || 'Dataset access request failed. Try again later.'}`;
                        setSnackbarMessage(errorMessage);
                        setSnackbarSeverity("error"); // Red
                    }
                    setSnackbarOpen(true);
                })
                .catch(err => {

                    if (err.response && err) {
                        // Handle specific error response
                        const errorMessage = `Error ${err.response.status}: ${err.response.statusText || 'No description available.'}`;
                        setSnackbarMessage(errorMessage);
                        setSnackbarSeverity("error"); // Red

                    } else {
                        // If API never called or another issue
                        const errorMessage = "Internal Server Error";
                        setSnackbarMessage(errorMessage);
                        setSnackbarSeverity("error"); // Red
                    }
                    setSnackbarOpen(true);
                });

            setPendingDataAccessRequests([...pendingDataAccessRequests, dataset.id]);
            setSnowModalOpen(false);
            setSuccessMsg(`Request successfully submitted for dataset "${dataset.name}"`);
            setIsSnackbarVisible(true);
            setTimeout(() => setIsSnackbarVisible(false), 1000 * 30)
        } else {
            setErrorAlertMessage(`Please fill in all required fields. ${inputDisqualifications.join(' ')}`);
        }
    }

    function processDatasetRows(records, searchTerm, sortField, sortAscending) {
        let processedRecords = records;
        processedRecords = filterSearch(processedRecords, searchTerm);
        processedRecords = sortDatasetRows(processedRecords, sortField, sortAscending);
        return processedRecords;
    }

    function filterSearch(records, searchTerm) {
        const fieldsOfInterest = [
            "name",
            "short_description",
            "description",
            "metadata",
            "size",
            "type",
            "location",
            "status",
            "concepts",
            "agreements",
            "source",
            "data_classification",
            "source_ou",
            "created",
            "created_by",
            "updated",
            "updated_by"
        ]
        const metadataFieldsOfInterest = [
            "accessible_via_hdp",
            "active_directory_name",
            "approved_uses_of_data",
            "approved_uses_of_data_description",
            "checksum",
            "collection_source",
            "collection_source_detail",
            "cost_centers",
            "data_format",
            "data_migration_path",
            "data_owner_name",
            "data_owner_network_id",
            "data_platform_provenance",
            "data_source_description",
            "data_type",
            "date_added",
            "de_identified",
            "geo_location_descriptions",
            "geo_location_tags",
            "is_mock",
            "last_updated",
            "legal_relationship_description",
            "legal_relationship_qualifier",
            "legal_relationship_qualifier_tag",
            "legal_relationship_tag",
            "provenance",
            "restriction_description",
            "restriction_tag",
            "secondary_data_owner_network_ids",
            "secondary_data_owners",
            "source_provenance",
            "storage_geo_tag_descriptions",
            "storage_geo_tags"
        ]

        // use stringify to print the JSON object fields from only the fields of interest to string and search in that string
        let filteredRows = records.filter(ds => JSON.stringify(ds, [...fieldsOfInterest, ...metadataFieldsOfInterest], 2).toLowerCase().includes(searchTerm.trim().toLowerCase()));

        //add an empty-string row. workaround needed as the table is not refreshing for empty row array
        if (filteredRows.length < 1) {
            const blank_row = Object.keys(records[0]).reduce(function (obj, x) {
                obj[x] = "";
                return obj;
            }, {});
            filteredRows.push({ ...blank_row, "name": "No matching datasets" });
        }

        return filteredRows;
    }

    function sortDatasetRows(records, sortField, sortAscending) {
        const sortFieldFunc = (a, b) => {
            let comparableA = a[sortField];
            let comparableB = b[sortField];

            if (comparableA.toLowerCase && comparableB.toLowerCase) {
                comparableA = comparableA.toLowerCase();
                comparableB = comparableB.toLowerCase();
            }

            const compareValue = comparableA < comparableB ? -1 : 1;
            const ascendingConstant = sortAscending ? 1 : -1;

            return compareValue * ascendingConstant;
        }

        return records.toSorted(sortFieldFunc);
    }

    function isAddDatasetDisabled() {

        console.log("Add dataset button clicked.... ");

        let isDisabled = true;

        for (let i = 0; i < user.roles.length; i++) {
            if ((user.roles[i].name == "technician") ||
                (user.roles[i].name == "data_scientist") ||
                (user.roles[i].name == "administrator") ||
                (user.roles[i].name == "data_owner")) {
                isDisabled = false;
            }
        }

        return isDisabled;
    }

    let isDisabled = isAddDatasetDisabled();
    let isVisible = false; //added this variable to make the visibility property explicit

    return <Paper size="xl" sx={{ padding: "1rem" }}>
        <Stack direction="row-reverse" paddingBottom="1rem">
            <SearchInput size={"small"} onChange={handleDatasetSearchChange} value={searchText} />
            <Stack>
                {isVisible && <CustomButton id="new-dataset" label="Add Data" disabled={isDisabled} />}
            </Stack>
        </Stack>
        <Box>
            <TableContainer
                rows={data}
                columns={columns}
                heading="Table"
                isLoading={false}
                allowSelect={false}
                hasError={false}
                elevation={2}
                zebra={true}
                rowsPerPageOptions={[10, 25, 50, 100]}
                defaultRowsPerPage={10}
                hidePagination={false}
                totalRowCount={data.length}
            />
            <RestNotifier
                eventMediator={eventMediator}
            />

            {/* Snackbar for displaying response messages */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{
                        width: '100%',
                        backgroundColor: snackbarSeverity === 'success' ? '#C8E6C9' : '#FFCCCB',
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
        <Modal
            open={viewRecordOpen}
            toggleOpenFn={() => setViewRecordOpen(!viewRecordOpen)}
        >
            <Box style={{ overflowY: 'auto', maxHeight: '90vh' }}>
                <DatasetViewModal contents={apiManager.datasetManager.dataCache[openDataset]} />
            </Box>
        </Modal>
        <Modal
            open={snowModalOpen}
            toggleOpenFn={() => setSnowModalOpen(!snowModalOpen)}
            primaryButtonLabel={<Typography>Submit</Typography>}
            primaryButtonHandler={handleDataAccessRequestSubmit}
            secondaryButtonLabel={<Typography>Cancel</Typography>}
            secondaryButtonHandler={() => setSnowModalOpen(false)}
        >
            <DatasetAccessRequestForm
                user={user}
                dataset={apiManager.datasetManager.dataCache[openDataset]}
                formValues={datasetAccessRequestFormValues}
                setFormValues={setDatasetAccessRequestFormValues}
                errorAlertMessage={errorAlertMessage}
                org_name={apiManager.operatingUnitManager.dataCache[user.operating_unit_id].name}
            />
        </Modal>
        {/* {isSnackbarVisible &&
            <AlertBanner
                alertMessage={<Typography variant="h4">{successMsg}</Typography>}
                alertType="confirm"
                bannerType="snackbar"
                snackbarAutoHideDuration={1000*30}
                snackbarHorizontalPos="center"
            />
        } */}
    </Paper>
}

function verifyDatasetAccessRequestForm(inputs) {
    console.log(`Verifying inputs: ${JSON.stringify(inputs)}`);
    // const flatInputs = flatten(inputs);
    // console.log(`Flattened inputs: ${JSON.stringify(flatInputs)}`);

    const disqualifications = [];

    if (Object.keys(inputs).length === 0) {
        return [false, 'The inputs are empty.']
    }

    // Conditions that will disqualify ANY input
    for (let key in inputs) {
        let label;
        if (key === "use_case_description") {
            label = "Description";
        } else if (key === "business_value") {
            label = "Business Value";
        } else if (key === "hdpar_cost_center") {
            label = "Cost Center";
        } else if (key === "use_cases") {
            label = "Use Cases";
        } else {
            label = key;
        }

        if (!inputs[key]) { // disqualify if the value is null
            disqualifications.push(`The value for ${label} is empty.`)
        } else if (typeof inputs[key] === 'object' && Array.isArray(inputs[key])) {
            if (inputs[key].length < 1) {
                disqualifications.push(`${label} does not have any values.`)
            }
        } else if (typeof inputs[key] === 'object') {
            if (Object.keys(inputs[key]).length === 0) {
                disqualifications.push(`${label} is empty of value.`)
            }
        }
    }

    return [disqualifications.length === 0, disqualifications]
}

export default DatasetsDisplayTable;
